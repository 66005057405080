
window.showMsgBox = function (config) {
    config = config || {};
    var msgBox = document.getElementById('msgBox');

    if (!msgBox) {
        msgBox = document.createElement('div');
        msgBox.id = 'msgBox';
        msgBox.innerHTML = '<div id="msgBox-main"><div id="msgBox-close"></div><div id="msgBox-title"></div><div id="msgBox-body"></div><div id="msgBox-footer"></div></div>';
        document.body.appendChild(msgBox);
        insertStyle ();
    }

    var msgBoxTitle = document.getElementById('msgBox-title');
    msgBoxTitle.innerHTML = config.title;
    var msgBoxBody = document.getElementById('msgBox-body');
    msgBoxBody.innerHTML = config.body;

    var btns = config.btns || [];
    var msgBoxFooter = document.getElementById('msgBox-footer');
    msgBoxFooter.innerHTML = '';
    for (var i = 0; i < btns.length; i++) {
        var btn = document.createElement('span');
        btn.innerText = btns[i].text;
        btn.onclick = btns[i].click;
        if (btns[i].icon) {
            btn.style.backgroundImage = 'url(' + btns[i].icon + ')';
            btn.style.backgroundRepeat = 'no-repeat';
            btn.style.backgroundPosition = '12px center';
            btn.style.backgroundSize = '14px';
            btn.style.paddingLeft = '30px';
        }
        msgBoxFooter.appendChild(btn);
    }

    var msgBoxClose = document.getElementById('msgBox-close');
    msgBoxClose.onclick = function () {
        msgBox.classList.remove('msgBox-show');
        config.close && config.close();
    };

    msgBox.classList.add('msgBox-show');

    // 插入样式
    function insertStyle () {
        var style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = [
            '#msgBox { position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 9999; background-color: rgba(0, 0, 0, 0.5); display: none; }',
            '#msgBox-main { position: absolute; top: 50%; left: 50%; width: 480px; margin-top: -90px; margin-left: -240px; background-color: rgba(255, 255, 255, 1); border-radius: 4px; border: 1px solid rgba(227, 229, 236, 1); box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08); box-sizing: border-box; padding: 24px; }',
            '#msgBox-close { position: absolute; top: 24px; right: 24px; width: 20px; height: 20px; background: url(//mail-activity.nos-jd.163yun.com/2b5ffa0809e951abffe2be5dcff81af7.png) center no-repeat; background-size: 20px; cursor: pointer; }',
            '#msgBox-title {  font-size: 16px; font-weight: 500; line-height: 24px; letter-spacing: 0px; text-align: left; background: rgba(33, 41, 58, 1); padding: 0 32px; background: url(//mail-activity.nos-jd.163yun.com/8001912201a1a684f68783c6cba7b8eb.png) left 0 no-repeat; background-size: 24px; }',
            '#msgBox-body { padding-top: 8px; font-size: 14px; font-weight: 400; line-height: 22px; letter-spacing: 0px; text-align: left; padding-left: 32px; }',
            '#msgBox-footer { padding-top: 24px; text-align: right; }',
            '#msgBox-footer span { display: inline-block; vartical-align: top; margin-left: 8px; padding: 5px 12px; border: 1px solid rgba(183, 188, 199, 1); cursor: pointer; font-size: 14px; font-weight: 400; line-height: 22px; letter-spacing: 0em; text-align: center; border-radius: 4px; }',
            '#msgBox-footer span:hover { background: rgba(33, 41, 58, 0.08); linear-gradient(0deg, rgba(33, 41, 58, 0.08), rgba(33, 41, 58, 0.08)), linear-gradient(0deg, #B7BCC7, #B7BCC7), linear-gradient(0deg, #FFFFFF, #FFFFFF); }',
            '#msgBox-footer span:active { background: rgba(33, 41, 58, 0.12); linear-gradient(0deg, rgba(33, 41, 58, 0.12), rgba(33, 41, 58, 0.12)), linear-gradient(0deg, #B7BCC7, #B7BCC7), linear-gradient(0deg, #FFFFFF, #FFFFFF);}',
            '#msgBox.msgBox-show { display: block; }',
        ].join('\n');
        document.head.appendChild(style);
    }
};

// 126邮箱登录提示
// window.showMsgBox({
//     title: '请跳转到新网址登录126邮箱',
//     body: '您使用的浏览器已禁用第三方cookie，导致无法在此页面登录，请点击下方按钮跳转至126邮箱登录。',
//     btns: [{
//         text: '跳转并登录126邮箱',
//         icon: '//mail-activity.nos-jd.163yun.com/160a05aed21ddf1f544806a639d8156f.png',
//         click: function () {
//             window.location.href = 'https://mail.126.com/';
//         }
//     }]
// });

// yeah邮箱登录提示
// window.showMsgBox({
//     title: '请跳转到新网址登录yeah邮箱',
//     body: '您使用的浏览器已禁用第三方cookie，导致无法在此页面登录，请点击下方按钮跳转至yeah邮箱登录。',
//     btns: [{
//         text: '跳转并登录yeah邮箱',
//         icon: '//mail-activity.nos-jd.163yun.com/fd351764f3f3f13e1113621d002c6b90.png',
//         click: function () {
//             window.location.href = 'https://mail.yeah.net/';
//         }
//     }]
// });

// 海外邮箱登录提示
// window.showMsgBox({
//     title: 'Please go to the new website to login to your 126 email account.',
//     body: 'The browser you are using has disabled third-party cookies, resulting in an inability to log in on this page. Please click the button below to go to the 126 email login page.',
//     btns: [{
//         text: 'Go to the new website',
//         icon: '//mail-activity.nos-jd.163yun.com/160a05aed21ddf1f544806a639d8156f.png',
//         click: function () {
//             window.location.href = 'https://mail.163.com/';
//         }
//     }]
// });

export default window.showMsgBox;