'use strict';

export default {
    COOKIE_PRE: 'login_', // 登录页cookie前缀


    // 各登录域名
    DOMAIN_EMAIL: 'email.163.com',
    DOMAIN_VIPMAIL: 'vipmail.163.com',
    DOMAIN_MAIL_HW: 'hw.mail.163.com',
    DOMAIN_MAIL_HW_VIP: 'hw.vip.163.com',
    
   
    // 登陆目标/导航切换目标
    TARGET_163: '163',
    TARGET_126: '126',
    TARGET_YEAH: 'yeah',
    TARGET_VIP163: 'vip163',
    TARGET_VIP126: 'vip126',
    TARGET_VIP188: 'vip188',
    
    NAV_LIST: ['163','126','yeah'],
    NAV_LIST_VIP: ['vip163','vip126','vip188'],

};