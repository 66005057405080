/**
 * 登录页配置
 * @author lemoncn
 */
//  枚举
// var ENUMS = require('./assets/enums');


// urs组件样式
var ursStyleAppend = {
    '163': '.g-bd{width:100%;padding-top:12px;}.m-cnt{padding:0 60px;}.headimg{display:none}.m-header{padding:0;height:30px;}.m-footer{display:none}.u-loginbtn{background: #4B91D8;background-image: linear-gradient(#64A5E6, #4086CE);border-radius:4px;border:#6699CC 1px solid}.u-loginbtn:hover{background-image: linear-gradient(#4289D0, #2173C7)}.u-loginbtn:active{color:#fff;background-image: linear-gradient(#2173C7, #4289D0)}.w-exception-normal{ margin: 0 auto;}.w-exception { margin: 0 auto;}.w-exception-normal .cnt .note{font-size:16px;margin-bottom:15px}.w-exception-normal .cnt{padding-top:10px}.m-cnt .inputbox{border-radius: 4px;}.yidun_popup.yidun_popup--light .yidun_popup__mask {opacity: 0 !important;}',
    '126': '.g-bd{width:100%;padding-top:12px;}.m-cnt{padding:0 60px;}.headimg{display:none}.m-header{padding:0;height:30px;}.m-footer{display:none}.u-loginbtn{background: #3B9F50;background-image: linear-gradient(#45A659, #3B9F50);border-radius:4px;border:#258436 1px solid}.u-loginbtn:hover{background-image: linear-gradient(#35AB49, #108B2A)}.u-loginbtn:active{color:#fff;background-image: linear-gradient(#108B2A, #35AB49)}.w-exception-normal{ margin: 0 auto;}.w-exception { margin: 0 auto;}.w-exception-normal .cnt .note{font-size:16px;margin-bottom:15px}.w-exception-normal .cnt{padding-top:10px}.m-cnt .inputbox{border-radius: 4px;}.yidun_popup.yidun_popup--light .yidun_popup__mask {opacity: 0 !important;}',
    'yeah': '.g-bd{width:100%;padding-top:12px;}.m-cnt{padding:0 60px;}.headimg{display:none}.m-header{padding:0;height:30px;}.m-footer{display:none}.u-loginbtn{background: #B7050A;background-image: linear-gradient(#D51A1F, #B7050A);border-radius:4px;border:#B41116 1px solid}.u-loginbtn:hover{background-image: linear-gradient(#C31519, #A5060A)}.u-loginbtn:active{color:#fff;background-image: linear-gradient(#A5060A, #C31519)}.w-exception-normal{ margin: 0 auto;}.w-exception { margin: 0 auto;}.w-exception-normal .cnt .note{font-size:16px;margin-bottom:15px}.w-exception-normal .cnt{padding-top:10px}.m-cnt .inputbox{border-radius: 4px;}.yidun_popup.yidun_popup--light .yidun_popup__mask {opacity: 0 !important;}',
    'vip163': '.g-bd{width:100%;padding-top:12px;}.m-cnt{padding:0 60px;}.headimg{display:none}.m-header{padding:0;height:30px;}.m-footer{display:none}.u-loginbtn{background: #3086bf;background-image: linear-gradient(#3086bf, #2d7eb3);border-radius:4px;border:none}.u-loginbtn:hover{background-image: linear-gradient(#5695bf, #508cb3)}.u-loginbtn:active{color:#fff;background-image: linear-gradient(#3086bf, #2d7eb3)}.w-exception-normal{ margin: 0 auto;}.w-exception { margin: 0 auto;}.w-exception-normal .cnt .note{font-size:16px;margin-bottom:15px}.w-exception-normal .cnt{padding-top:10px}.m-cnt .inputbox{border-radius: 4px;}.yidun_popup.yidun_popup--light .yidun_popup__mask {opacity: 0 !important;}',
    'vip126': '.g-bd{width:100%;padding-top:12px;}.m-cnt{padding:0 60px;}.headimg{display:none}.m-header{padding:0;height:30px;}.m-footer{display:none}.u-loginbtn{background: #30bfa7;background-image: linear-gradient(#30bfa7, #2db39d);border-radius:4px;border:none}.u-loginbtn:hover{background-image: linear-gradient(#56bfae, #50b4a3)}.u-loginbtn:active{color:#fff;background-image: linear-gradient(#30bfa7, #2db39d)}.w-exception-normal{ margin: 0 auto;}.w-exception { margin: 0 auto;}.w-exception-normal .cnt .note{font-size:16px;margin-bottom:15px}.w-exception-normal .cnt{padding-top:10px}.m-cnt .inputbox{border-radius: 4px;}.yidun_popup.yidun_popup--light .yidun_popup__mask {opacity: 0 !important;}',
    'vip188': '.g-bd{width:100%;padding-top:12px;}.m-cnt{padding:0 60px;}.headimg{display:none}.m-header{padding:0;height:30px;}.m-footer{display:none}.u-loginbtn{background: #bf8f30;background-image: linear-gradient(#bf8f30, #b3862d);border-radius:4px;border:none}.u-loginbtn:hover{background-image: linear-gradient(#cca559, #c29e57)}.u-loginbtn:active{color:#fff;background-image: linear-gradient(#bf8f30, #b3862d)}.w-exception-normal{ margin: 0 auto;}.w-exception { margin: 0 auto;}.w-exception-normal .cnt .note{font-size:16px;margin-bottom:15px}.w-exception-normal .cnt{padding-top:10px}.m-cnt .inputbox{border-radius: 4px;}.yidun_popup.yidun_popup--light .yidun_popup__mask {opacity: 0 !important;}'
};


export default {
    // entry所需要的基础参数  get参数 https://wiki.mail.netease.com/pages/viewpage.action?pageId=17138019
    entryBase: {
        'language': '1',
        'product': 'mail163',
        'style': '1',
        'allssl': 'true',
        'url2': 'https://email.163.com/', // 失败都去email登录页
        'verifycookie': '1',
        'lightweight': '1',
        'dist': 'hw',
        'mail_idc': 'hwmimg.127.net',
    },
    // urs需要的基础参数
    ursBase: {
        swidth: '340px',
        mode: 'float',
        newCDN: 1,
        version: 4,
        skin: 1,
        lang: 'en',
        enlang: 1,
        product: 'mail163',
        promark: 'fjWGUOS',
        productKey: 'a6fe40ace298333b4916c840aa237398',
        host: 'email.163.com',
        chromeSupport: 1,
        page: 'login',
        prdomain: '@163.com',
        single: 1,
        placeholder: {
            account: 'Email',
            pwd: 'Password'
        },
        gotoRegText: 'Create account',
        needQrLogin: false,
        logincb: function (_username) {
            //  登录成功
        },
        initReady: function () {
            // 组件初始化成功
        },
        moduleResize: function(_opt) {
            // urs窗口发生变化
        },
        // regUrl: 'https://reg.mail.163.com/mailregAll/reg0.jsp?from=email163&regPage=163',
        regUrl: 'https://mail.163.com/register/index.htm?from=hwmail'
    },


    // 分域的各项配置细项
    scope: {
        // 海外版
        'hw.mail.163.com': {
            '163': {
                // 版本选择
                styleOptions: [{
                    txt: '网易邮箱6.0版',
                    value: '7'
                }],
                sslAble: true,
                entry: 'https://hw.mail.163.com/entry/cgi/ntesdoor?t=' + new Date().getTime(),
                entryParma: {
                    'df': 'hwmail163',
                    'product': 'mail163',
                    'webhost': 'hw.mail.163.com',
                },
                ursParma: {
                    host: 'hw.mail.163.com',
                    includeBox: 'panel-163',
                    prdomain: '@163.com',
                    product: 'mail163',
                    promark: 'wHAyYNG',
                    productKey: 'ed5229b8fe3618a74e2b8bc6cb17c62c',
                    style: ursStyleAppend['163'],
                    // regUrl: 'https://reg.mail.163.com/mailregAll/reg0.jsp?from=email163&regPage=163'
                    regUrl: 'https://mail.163.com/register/index.htm?from=163mail'
                }
            },
            '126': {
                // 版本选择
                styleOptions: [{
                    txt: '网易邮箱6.0版',
                    value: '7'
                }],
                sslAble: true,
                entry: 'https://hw.mail.126.com/entry/cgi/ntesdoor?t=' + new Date().getTime(),
                entryParma: {
                    'df': 'hwmail126',
                    'product': 'mail126',
                    'webhost': 'hw.mail.126.com',
                },
                ursParma: {
                    host: 'hw.mail.163.com',
                    includeBox: 'panel-126',
                    prdomain: '@126.com',
                    cookieDomain: '126.com',
                    chromeSupport: 1,
                    domains: '126.com,163.com',
                    product: 'mail126',
                    promark: 'smXNcQy',
                    productKey: '91d7dedd5a13efba22e63f66ee7da656',
                    style: ursStyleAppend['126'],
                    // regUrl: 'https://reg.mail.163.com/mailregAll/reg0.jsp?from=email163&regPage=126'
                    regUrl: 'https://mail.163.com/register/index.htm?from=126mail'
                }
            },
            'yeah': {
                // 版本选择
                styleOptions: [{
                    txt: '网易邮箱6.0版',
                    value: '7'
                }],
                sslAble: true,
                entry: 'https://hw.mail.yeah.net/entry/cgi/ntesdoor?t=' + new Date().getTime(),
                entryParma: {
                    'df': 'hwmailyeah',
                    'product': 'mailyeah',
                    'webhost': 'hw.mail.yeah.net',
                },
                ursParma: {
                    host: 'hw.mail.163.com',
                    includeBox: 'panel-yeah',
                    prdomain: '@yeah.net',
                    cookieDomain: 'yeah.net',
                    chromeSupport: 1,
                    domains: 'yeah.net,163.com',
                    product: 'mailyeah',
                    promark: 'VyFicft',
                    productKey: 'bf3c2a8bbe64d2bb9264a6226d21da8b',
                    style: ursStyleAppend['yeah'],
                    // regUrl: 'https://reg.mail.163.com/mailregAll/reg0.jsp?from=email163&regPage=yeah'
                    regUrl: 'https://mail.163.com/register/index.htm?from=yeah'
                }
            }
        },

        // vip hw
        'hw.vip.163.com': {
            'vip163': {
                // 版本选择
                styleOptions: [],
                entry: 'https://hwentry.vip.163.com/cgi/ntesdoor?t=' + new Date().getTime(),
                entryParma: {
                    df: 'hwvip163',
				    product: 'mailvip',
                    enterVip: true,
                    webhost: 'hwwebmail.vip.163.com',
                    url2: 'https://vipmail.163.com',
                },
                ursParma: {
                    host: 'hw.vip.163.com',
                    includeBox: 'panel-vip163',
                    prdomain: '@vip.163.com',
                    product: 'hwvipmail',
                    promark: 'qXEBGuJ',
                    productKey : '0012120a730c73d260b6b1b5696fc120',
                    cookieDomain : 'vip.163.com',
                    domains: '163.com',
                    style: ursStyleAppend['vip163'],
                    regUrl: 'https://reg1.vip.163.com/newReg1/reg?from=new_hwvipmail_login&utm_source=new_hwvipmail_login'
                }
            },
            'vip126': {
                // 版本选择
                styleOptions: [],
                entry: 'https://hwentry.vip.126.com/entry/door?t=' + new Date().getTime(),
                entryParma: {
                    df: 'hwvip126',
				    product: 'mailvip',
                    enterVip: true,
                    webhost: 'hwwebmail.vip.126.com',
                    url2: 'https://vipmail.163.com',
                },
                ursParma: {
                    host : 'hw.vip.163.com',
                    includeBox: 'panel-vip126',
                    prdomain: '@vip.126.com',
                    cookieDomain : 'vip.126.com',
                    domains: '163.com,126.com',
                    product : 'hwvipmail',
                    promark : 'qXEBGuJ',
                    productKey : '0012120a730c73d260b6b1b5696fc120',
                    style: ursStyleAppend['vip126'],
                    regUrl: 'https://reg1.vip.163.com/newReg1/reg?from=new_hwvipmail_login&utm_source=new_hwvipmail_login'
                }
            },
            'vip188': {
                // 版本选择
                styleOptions: [],
                entry: 'https://hwentry.vip.188.com/cgi/ntesdoor?t=' + new Date().getTime(),
                entryParma: {
                    df: 'hwvip188',
				    product: 'mailvip',
                    enterVip: true,
                    webhost: 'hwwebmail.vip.188.com',
                    url2: 'https://vipmail.163.com',
                },
                ursParma: {
                    host : 'hw.vip.188.com',
                    includeBox: 'panel-vip188',
                    prdomain: '@188.com',
                    cookieDomain : '188.com',
                    domains: '163.com,188.com',
                    product : 'hwvipmail',
                    promark : 'qXEBGuJ',
                    productKey : '0012120a730c73d260b6b1b5696fc120',
                    style: ursStyleAppend['vip188'],
                    regUrl: 'https://reg1.vip.163.com/newReg1/reg?from=new_hwvipmail_login&utm_source=new_hwvipmail_login'
                }
            }
        }
    },
    errorInfo: {
        'noId': {
            'title': 'Please enter your email account first.'
        },
        'noPhone': {
            'title': 'Please enter your mobile phone number'
        },
        'noPw': {
            'title': 'Please enter your password.'
        },
        'inputWrong': {
            'title': 'Account or password incorrect',
            'info': '1. Please check the account spelling, make sure there are no input errors.<br />2. If the account has not been logged in for a long time, it may have been deactivated.<br/>　&nbsp;Please<a href="https://reg.mail.163.com/unireg/call.do?cmd=register.entrance" target="_blank">register again</a><br />3. If you forget your password, please<a href="https://reg.163.com/getpasswd/RetakePassword.jsp?from=email163" target="_blank">retrieve password</a><br/>4. If you need to lock this account, please<a href="https://reg.163.com/lockuser/LockUserByPingma_0.jsp" target="_blank">click here</a><br/>5. If the phone number associated with the email has been changed,<br/>　&nbsp;Can <a href="https://reg.163.com/mobilealias/findBindHistoryIndex.do" target="_blank">retrieve the original account</a>'
        },
        'idLocked': {
            'title': 'Sorry! Your account has been locked and you are temporarily unable to log in.'
        },
        'systemBusy': {
            'title': 'The busy system needs to take a break temporarily. Please try again later.'
        },
        'loginWrong': {
            'title': 'Your login attempts are too frequent, please try again later.'
        },
        '_mobileNotAvailable': {
            'title': 'This account does not exist!',
            'info': 'Hint: <br/>The phone number email has not been activated yet.<a href="https://reg.mail.163.com/unireg/call.do?cmd=register.entrance&flow=mobile&from=email_mobile" target="_blank">Free activation</a>'
        }
    }
}
