'use strict';

/**
 * 工具
 * @author chenshouzhe@corp.netease.com
 */
import Enums from './enums';



var utils = {

    id: function (string) {
        return document.getElementById(string);
    },

    /**
     * 绑定事件
     * 
     * @param {any} obj dom
     * @param {any} type 基础事件类型
     * @param {any} fn handler
     */
    addEvent: function (obj, type, fn) {
        if (obj.attachEvent) {
            obj['e' + type + fn] = fn;
            obj[type + fn] = function () {
                obj['e' + type + fn](window.event);
            }
            obj.attachEvent('on' + type, obj[type + fn]);
        } else
            obj.addEventListener(type, fn, false);
    },
    mouseleave: function (obj, fn) {
        this.addEvent(obj, 'mouseout', function (e) {

            var p = (e.type == "mouseover" ? e.fromElement : e.toElement) || e.relatedTarget;
            // Traverse up the tree
            while (p && p != this) p = p.parentNode;
            // If we actually just moused on to a sub-element, ignore it
            if (p == this) return false;

            fn && fn.call(obj);
        });

    },
    /**
     * 移除事件
     * 
     * @param {any} obj dom
     * @param {any} type 基础事件类型
     * @param {any} fn handler
     */
    removeEvent: function (obj, type, fn) {
        if (obj.detachEvent) {
            obj.detachEvent('on' + type, obj[type + fn]);
            obj[type + fn] = null;
        } else
            obj.removeEventListener(type, fn, false);
    },
    /**
     * 读cookie
     * 
     * @param {string} sName cookie名
     * @returns 
     */
    getCookie: function (sName) {
        var sSearch = Enums.COOKIE_PRE + sName + "=";
        if (document.cookie.length > 0) {
            var offset = document.cookie.indexOf(sSearch)
            if (offset != -1) {
                offset += sSearch.length;
                var end = document.cookie.indexOf(";", offset)
                if (end == -1) end = document.cookie.length;
                var oRawString = unescape(document.cookie.substring(offset, end));

                // 过滤
                if ((typeof oRawString == 'string') && oRawString.constructor == String) {
                    oRawString = oRawString.replace(/[\<\>\'\"\;]/g, "");
                }

                return oRawString;
            } else return ""
        }
    },
    /**
     * 写cookie
     * 
     * @param {string} name cookie名
     * @param {string} value 值
     * @param {boolean} isForever 是否长期cookie（2099）
     * @param {boolean} isPrefix 是否要前缀，默认true
     */
    setCookie: function (name, value, isForever, isPrefix) {

        if (typeof isPrefix == "undefined") {
            isPrefix = true;
        }

        // 过滤
        // if ((typeof value == 'string') && value.constructor == String) {
        //     value = value.replace(/[\<\>\'\"\;]/g, "");
        // }
        // var sDomain = ";domain=" + (domain || 'email.163.com');
        document.cookie = (isPrefix ? Enums.COOKIE_PRE : '') + name + "=" + escape(value) + (isForever ? ";expires=" + (new Date(2099, 12, 31)).toGMTString() : "");
    },


    /**
     * 是否是有效的导航切换目标
     * 
     * @param {any} target 要切换到的值
     */
    beValidTarget: function (target) {
        if (Enums.NAV_LIST.indexOf(target) > -1 || Enums.NAV_LIST_VIP.indexOf(target) > -1) {
            return true;
        } else {
            return false;
        }
    },
    /**
     * 给form添加 name-value 
     * 
     * @param {any} ofrom form的dom 
     * @param {any} name name值
     * @param {any} value 值
     */
    formAdd: function (ofrom, name, value) {
        if (!value) {
            value = 'null';
        }
        var input = document.createElement("input");
        input.type = "hidden";
        input.name = name;
        input.value = value;
        ofrom.appendChild(input);
    },
    /**
     * 获取参数值
     * 
     * @param {any} name 
     * @param {any} bNotEscape 
     * @returns 
     */
    getQuery: function (name, bNotEscape) {
        var sUrl = window.location.search.substr(1);
        var r = sUrl.match(new RegExp("(^|&)" + name + "=([^&]*)(&|$)"));
        if (bNotEscape) {
            return (r == null ? null : r[2]);
        } else {
            return (r == null ? null : unescape(r[2]));
        }
    },
    /**
     * 获取#参数值
     * 
     * @param {any} name 
     * @returns 
     */
    getQueryHash: function (name) {
        var sUrl = window.location.hash.substr(1);
        var r = sUrl.match(new RegExp("(^|&)" + name + "=([^&]*)(&|$)"));
        return (r == null ? null : unescape(r[2]));
    },

    /**
     * 加载js文件
     * 
     * @param {string} sUrl 
     * @param {function} fSuc 
     * @param {function} fErr 
     */
    getScript: function (sUrl, fSuc, fErr) {
        var oScript = document.createElement("script");
        oScript.setAttribute("type", "text/javascript");
        oScript.setAttribute("src", sUrl);
        try {
            oScript.setAttribute("defer", "defer");
        } catch (e) { }
        window.document.body.appendChild(oScript);
        if (oScript.onreadystatechange !== undefined) {
            oScript.onreadystatechange = function () {
                if (!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete') {
                    fSuc && fSuc();
                }
            };
        } else {
            oScript.onload = function () {
                if (!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete') {
                    fSuc && fSuc();
                }
            };
        }

        oScript.onerror = function () {
            fErr && fErr();
        };
    },

    /**
     * 对象浅拷贝
     *
     * @param {object} obj
     * @returns object
     */
    objCopy: function (obj) {
        var newObj = {};
        for (var item in obj) {
            newObj[item] = obj[item];
        }
        return newObj;
    },

    /**
     * 获取urs 备份文件
     *
     */
     loadBackupMessage(scriptPath, fSuccessCb, fErrorCb) {
        var cdnPath = scriptPath;
        var script = document.createElement('script');
        script.src = cdnPath;
        script.onerror = () => {
            fErrorCb && fErrorCb();
        };
        script.onload = () => {
            fSuccessCb && fSuccessCb();
        };
        document.body.appendChild(script);
    }

}

export default utils;